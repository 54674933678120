<template>  
  <base-section
    id="contact-us"
    
  >
    <base-section-heading
      color="grey lighten-2"      
      title="Контакты"
    />

    <v-container>
      <v-responsive
        class="mx-auto align-center text-center"
        max-width="1024"
      >
        <v-row class="overflow-hidden text-center mx-0">
            <v-col style="min-width: 320px;">                        
                <h3 class="text-h6 text-sm-h5">ООО «ИЛ Кооператив»</h3>
            </v-col>            
        </v-row>
        <v-row class="mt-4">
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
                <v-row>
                    <v-col cols="3">
                        <div class="font-weight-bold">Адрес:</div>
                    </v-col>
                    <v-col>
                        <div></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <div class="font-weight-bold">Телефон:</div>
                    </v-col>
                    <v-col>
                        <div><span v-html="tel_link"></span></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <div class="font-weight-bold">E-mail:</div>
                    </v-col>
                    <v-col>
                        <div><span v-html="mail_link"></span></div>
                    </v-col>
                </v-row>                
            </v-col>
            <v-col  
                sm="6"                    
                md="8"
            >
                <div>
                    <div class="map" id="office" style="height: min(calc(100vh - 100px), 400px);"></div>
                </div>
            </v-col>
        </v-row>                
      </v-responsive>
    </v-container>
  </base-section>  
</template>

<script>
  export default {
    name: 'SectionContactUs',

        data: () => ({    
        mail_link: '<a href="mailto:"></a>',
        tel_link: '<a href="tel:"></a>',
    }),
  }
</script>
